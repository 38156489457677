<template>
  <div class="a-tree" :style="CSS" :class="CSSClasses">
    <div class="background">
      <div class="header_fixed">
        <el-button
          style="margin-left: 10px"
          icon="el-icon-plus"
          size="small"
          @click="addEntity"
          plain>
          {{ $locale.main.button.add }}
        </el-button>
        <el-input v-model="searchText"
                  v-if="tree.searchModeEnabled"
                  @keyup.enter.native="onSearch"
                  @input="onClear"
                  class="search_input"
                  clearable
                  size="mini"
                  style="width: 75%; padding: 2px; margin: 0 1% 0 0; float: right"
                  :placeholder="$locale.main.fields.search">
          <template slot="prepend"><i class="el-icon-search"></i></template>
        </el-input>
      </div>
    </div>
    <el-tree
      ref="Atree"
      :lazy="!searchMode"
      :load="loadNode"
      :data="data"
      :key="refreshTree"
      :node-key="generateGuid()"
      :expand-on-click-node="false"
      :default-expand-all="tree.loadAllData || searchMode"
      @node-click="handleNodeClick"
      :props="defaultProps">
      <div :class="{ 'selected-node custom-tree-node': selectedNode.id === node.data.id }" slot-scope="{ node, data }">
        <div @click="clickNode ? openCard(data, node) : ''" v-html="node.data.name" style="width: 100%"></div>
        <div v-show="selectedNode.id === node.data.id" class="tree-node-buttons">
          <el-button
            v-if="!clickNode"
            v-show="data.openBtnHidden"
            type="text"
            size="mini"
            :icon="compactView ? 'el-icon-edit' : ''"
            @click.stop="openCard(data, node)">
            {{ compactView ? '' : $locale.main.button.open }}
          </el-button>
          <el-button
            v-show="data.hideAddCard"
            type="text"
            size="mini"
            :icon="compactView ? 'el-icon-plus' : ''"
            @click.stop="addEntity(data, node)">
            {{ compactView ? '' : $locale.main.button.add }}
          </el-button>
          <el-popover placement="top" width="160" v-show="data.deleteBtnHidden" v-model="node.popoverVisible">
            <p>{{ $locale.main.message.confirm }}</p>
            <div style="text-align: right; margin: 0">
              <el-button size="small" type="text" @click="node.popoverVisible = false">{{ $locale.main.button.cancel }}</el-button>
              <el-button type="primary" size="small" @click="deleteEntity(data)">{{ $locale.main.button.delete }}</el-button>
            </div>
            <el-button
              type="text"
              size="small"
              style="color:red; margin-left: 5px"
              :icon="compactView ? 'el-icon-delete' : ''"
              @click.stop
              slot="reference">
              {{ compactView ? '' : $locale.main.button.delete }}
            </el-button>
          </el-popover>
        </div>
      </div>
    </el-tree>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import RegistryCard from '@/components/RegistryCard'
import Registry from '@/components/Registry/Models/Registry'
import conditionsMixin from '@/components/InterfaceEditor/components/conditions_mixin'
import Node from 'element-ui/packages/tree/src/model/node'
import FilterBuilder, { EComponentTypes } from '../utils'
import refreshComponentsMixin from '@/components/InterfaceEditor/components/refreshComponentsMixin'
export default {
  name: 'a-tree',
  components: {
    RegistryCard
  },
  inject: {
    openRegistryCard: {
      default: () => {}
    },
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin, conditionsMixin, refreshComponentsMixin],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    name: {
      type: String,
      description: 'Атрибут'
    },
    tree: {
      type: Object,
      default () {
        return {
          addBtnHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          },
          deleteBtnHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          },
          openBtnHidden: {
            type: 'never',
            condition_type: 'and',
            conditions: []
          }
        }
      },
      editor: 'Tree'
    },
    defaultGroup: {
      type: String,
      description: 'default_group_attr'
    },
    compactView: {
      type: Boolean,
      description: 'compact_view'
    },
    clickNode: {
      type: Boolean,
      description: 'click_node'
    },
    frameGuid: {
      type: String,
      description: 'frame_guid',
      editor: 'Frames'
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    },
    defaults: {
      type: Array,
      default: () => {
        return []
      },
      editor: 'Filters',
      options: {
        title: 'По умолчанию',
        showXrefOption: false,
        showEqualsTypes: false
      }
    }
  },
  data () {
    return {
      registryId: this.tree.registryId,
      data: [],
      searchText: '',
      searchMode: false,
      refreshTree: 0,
      selectedNode: {},
      defaultProps: {
        isLeaf: 'leaf',
        label: 'name',
        children: 'children'
      }
    }
  },
  computed: {
    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.tree
      )

      return builder.buildAsRegistryService()

      // let filters = []
      // if (this.filters) {
      //   this.filters.forEach(item => {
      //     let type = 'eq'
      //     if (item.isXref) {
      //       type = 'eqx'
      //     }
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute] && item.alias && this.getModel()[item.attribute] + '') {
      //         filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
      //       }
      //     } else if (item.type === 'constant' && item.alias) {
      //       filters.push(`${item.alias},${type},${item.attribute}`)
      //     } else if (item.type === 'current_user' && item.alias) {
      //       filters.push(`${item.alias},${type},${this.$store.getters['Authorization/userId']}`)
      //     }
      //   })
      // }
      // return filters
    }
  },
  watch: {
    async dataFilters (val, oldval) {
      if (val.length || oldval.length) {
        this.data = await this.loadEntities()
      }
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  methods: {
    handleNodeClick (data) {
      if (this.selectedNode.id === data.id) {
        this.selectedNode = {}
        this.$emit('input')
        return
      }
      this.selectedNode = data

      if (this.name) {
        if (this.tree.keyField) {
          let dataToInsert = this.selectedNode.entity[`attr_${this.tree.keyField}_`]
          this.$emit('input', dataToInsert)
        } else {
          console.error('Не указано ключевое поле! Завершите настройку компонента.')
        }
      }
    },
    async addEntity (data, parent = null) {
      let isQuickAddCard = await this.isQuickAddCard()
      if (isQuickAddCard) {
        this.openQuickAddCard(isQuickAddCard, parent)

        return
      }
      let initialData = this.initialDataForCard(parent)
      let card = await this.getCardId()
      if (card) {
        let callback = async (recordId) => {
          let dataCard = await this.getCardData(recordId)
          let customNode = this.buildNode(dataCard, true)
          if (parent) {
            if (parent.childNodes.length) {
              this.$refs.Atree.append(customNode, parent)
            } else {
              this.refreshTree += 1
            }
          } else {
            this.refreshTree += 1
            // let node = new Node({ parent: this.$refs.Atree.root, store: this.$refs.Atree.store, data: customNode })
            // node.level = 1
            // this.$refs.Atree.root.childNodes.push(node)
          }
        }
        if (this.tree.openInWindow) {
          const h = this.$createElement
          let customClass = 'custom_scrollbar '
          if (this.tree.windowWidth) {
            customClass += `dashboard_window_width_${this.tree.windowWidth}`
          }
          let me = this
          await this.$msgbox({
            title: me.tree.windowTitle,
            customClass: customClass,
            message: h('registry-card', {
              style: {
                height: me.tree.windowHeight
              },
              props: {
                cardId: me.tree.cardId || card.id,
                registryId: me.tree.registryId,
                recordId: null,
                initialData: initialData
              },
              on: {
                cancelChanges: function () {
                  me.$msgbox.close()
                },
                recordAdded: function () {
                  me.$msgbox.close()
                  me.refreshTree += 1
                }
              },
              key: this.generateGuid()
            }),
            showCancelButton: false,
            showConfirmButton: false,
            closeOnClickModal: false
          })
        } else if (this.frameGuid) {
          let frame = (this.getDashboardComponents()[`component_${this.frameGuid}`] || [])[0]
          if (!frame) {
            console.warn('frame not found', this.frameGuid)
            return false
          }

          frame.openCard({
            cardId: card.id,
            registryId: this.registryId,
            addCallback: callback,
            initialData: { ...initialData, [this.defaultGroup]: data.id }
          })
        } else {
          this.openRegistryCard({
            registryId: this.registryId,
            cardId: card.id,
            cardName: card.name,
            recordId: null,
            initialData: { ...initialData, [this.defaultGroup]: data.id },
            preventUserCard: true,
            registry: {
              addRecord: callback,
              updateRecord: () => {}
            }
          })
        }
      }
    },
    async deleteEntity (data) {
      await this.$http.delete(`${this.$config.api}/registryservice/registry/${this.registryId}/records/${data.id}`)
      this.$refs.Atree.remove(data.id)
      this.refreshTree += 1
    },
    async isQuickAddCard () {
      // Проверка на карточку быстрого добавления
      let registryData = new Registry({ id: this.registryId })
      let me = this
      let structure = await registryData.structure().first()
        .catch(() => { me.error = true })
      if (!structure) {
        return false
      }
      let quickAddCard = (structure.properties || []).find((item) => item.id === 'quick_add_card') || {}
      if (quickAddCard.value && quickAddCard.value.card_id) {
        return quickAddCard
      } else {
        return false
      }
    },
    openQuickAddCard (quickAddCard, parent) {
      const h = this.$createElement
      let customClass = 'custom_scrollbar '
      if (quickAddCard.value.width) {
        customClass += `window_width_${quickAddCard.value.width}`
      }
      let me = this
      this.$msgbox({
        customClass: customClass,
        message: h('registry-card', {
          style: {
            height: quickAddCard.value.height
          },
          props: {
            cardId: quickAddCard.value.card_id,
            registryId: this.registryId,
            parentContext: null,
            model: {},
            quick: true,
            initialData: { [this.defaultGroup]: this.selectedNode.id }
          },
          on: {
            'quick-add': async function (data) {
              let cardFast = await me.getFastCard(data)
              me.openRegistryCard({
                registryId: me.registryId,
                cardId: cardFast.id,
                cardName: cardFast.name,
                recordId: null,
                initialData: data,
                preventUserCard: true,
                registry: {
                  addRecord: async (recordid) => {
                    let dataCard = await me.getCardData(recordid)
                    let customNode = me.buildNode(dataCard, true)
                    if (parent) {
                      me.$refs.Atree.append(customNode, parent)
                    } else {
                      let node = new Node({ parent: me.$refs.Atree.root, store: me.$refs.Atree.store, data: customNode })
                      node.level = 1
                      me.$refs.Atree.root.childNodes.push(node)
                    }
                    me.refreshTree += 1
                  },
                  updateRecord: () => {}
                }
              })
              me.$msgbox.close()
            },
            cancelChanges: function () {
              me.$msgbox.close()
            }
          },
          key: this.generateGuid() }),
        showCancelButton: false,
        showConfirmButton: false,
        closeOnClickModal: false
      })
    },
    async loadNode (node, resolve) {
      if (this.registryId && this.defaultGroup) {
        if (node.level === 0) {
          resolve(await this.loadEntities())
        } else {
          resolve(await this.loadEntities(node.data.id))
        }
      }
    },
    async getFastCard (recordData = null) {
      let data = await this.$http.post(`${this.$config.api}/registryservice/registry/${this.registryId}/card`,
        recordData, { hideNotification: true })
      return data.data[0]
    },
    async getCardId (recordId = null) {
      let url = `${this.$config.api}/registryservice/registry/${this.registryId}/card`
      if (recordId) {
        url = `${this.$config.api}/registryservice/registry/${this.registryId}/records/${recordId}/card`
      }
      let data = await this.$http.get(url)
      return data.data[0]
    },
    async getCardData (recordid) {
      let dataCard = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.registryId}/card/${recordid}`)
      return dataCard.data
    },
    async openCard (data, parent = null) {
      let card = await this.getCardId(data.id)
      let initialData = this.initialDataForCard(parent)
      if (card) {
        this.selectedNode = data
        let callback = async (recordid) => {
          let dataCard = await this.getCardData(recordid)
          let customNode = this.buildNode(dataCard, true)
          this.$set(this.$refs.Atree.getCurrentNode(), 'name', customNode.name)
          this.$set(this.$refs.Atree.getCurrentNode(), 'id', customNode.id)
        }
        if (this.tree.openInWindow) {
          const h = this.$createElement
          let customClass = 'custom_scrollbar '
          if (this.tree.windowWidth) {
            customClass += `dashboard_window_width_${this.tree.windowWidth}`
          }
          let me = this
          await this.$msgbox({
            title: me.tree.windowTitle,
            customClass: customClass,
            message: h('registry-card', {
              style: {
                height: me.tree.windowHeight
              },
              props: {
                cardId: me.tree.cardId || card.id,
                registryId: me.tree.registryId,
                recordId: data.id,
                initialData: initialData
              },
              on: {
                cancelChanges: function () {
                  me.$msgbox.close()
                },
                recordEdited: function () {
                  me.$msgbox.close()
                  me.refreshTree += 1
                }
              },
              key: this.generateGuid()
            }),
            showCancelButton: false,
            showConfirmButton: false,
            closeOnClickModal: false
          })
        } else if (this.frameGuid) {
          let frame = (this.getDashboardComponents()[`component_${this.frameGuid}`] || [])[0]
          if (!frame) {
            console.warn('frame not found', this.frameGuid)
            return false
          }

          frame.openCard({ cardId: card.id, registryId: this.registryId, recordId: data.id, updateCallback: callback })
        } else {
          this.openRegistryCard({
            registryId: this.registryId,
            cardId: card.id,
            cardName: card.name,
            recordId: data.id,
            preventUserCard: true,
            registry: {
              addRecord: () => {},
              updateRecord: callback
            }
          })
        }
      }
    },
    async onSearch (event) {
      this.$set(this, 'searchMode', true)
      this.refreshTree += 1
      const attributeRegex = /attr_\d+_/gm
      let attribute = this.tree.htmlTemplate
      if (attributeRegex.test(attribute)) {
        let mainAttribute = attribute.match(attributeRegex)[0]
        this.data = await this.searchTreeEntities(mainAttribute)
      }
    },
    async onClear (value = '') {
      if (value === '') {
        this.$set(this, 'searchMode', false)
        this.refreshTree += 1
      }
    },
    async loadEntities (id = null) {
      let response
      if (!id) {
        response = await this.$http
          .get(`${this.$config.api}/registryservice/registry/${this.registryId}/data?limit=${this.tree.limit || 100}&isTree=true&loadAll=${this.tree.loadAllData}&filter[0]=${this.defaultGroup},eq,[]&${this.dataFilters.map((filter, index) => `filter[${index + 1}]=${filter}`).join('&')}`)
      } else {
        response = await this.$http
          .get(`${this.$config.api}/registryservice/registry/${this.registryId}/data?limit=${this.tree.limit || 100}&isTree=true&loadAll=${this.tree.loadAllData}&filter[0]=${this.defaultGroup},eqx,${id}&${this.dataFilters.map((filter, index) => `filter[${index + 1}]=${filter}`).join('&')}`)
      }
      return this.buildNode(response.data.data)
    },
    async searchTreeEntities (mainAttribute, id = null) {
      let response
      if (!id) {
        response = await this.$http
          .get(`${this.$config.api}/registryservice/registry/${this.registryId}/treeData?mainAttribute=${mainAttribute}&searchText=${this.searchText}&loadAll=${this.tree.loadAllData}&filter[0]=${this.defaultGroup},eq,[]&${this.dataFilters.map((filter, index) => `filter[${index + 1}]=${filter}`).join('&')}`)
      } else {
        response = await this.$http
          .get(`${this.$config.api}/registryservice/registry/${this.registryId}/treeData?mainAttribute=${mainAttribute}&searchText=${this.searchText}&loadAll=${this.tree.loadAllData}&filter[0]=${this.defaultGroup},eqx,${id}&${this.dataFilters.map((filter, index) => `filter[${index + 1}]=${filter}`).join('&')}`)
      }
      return this.parseHtmlSearchTemplate(response.data.data)
    },
    buildNode (response, addRecord = false) {
      if (addRecord) {
        return this.parseHtmlTemplate(response)
      }
      return response.map(this.parseHtmlTemplate)
    },
    parseHtmlSearchTemplate (item, parentId = null) {
      let result = []
      for (let el in item) {
        let hideAddCard = !this.checkConditions(this.tree.addBtnHidden, true, item[el])
        let deleteBtnHidden = !this.checkConditions(this.tree.deleteBtnHidden, true, item[el])
        let openBtnHidden = !this.checkConditions(this.tree.openBtnHidden, true, item[el])
        let showLeaf = item.children_count === 0
        if (item[el][`${this.defaultGroup}id`] === parentId) {
          let attributes = this.tree.htmlTemplate.match(/\{{(.*?)\}}/g) || []
          let label = this.tree.htmlTemplate
          attributes.forEach((attribute) => {
            attribute = attribute.replace('{{', '').replace('}}', '')
            let value = item[el][attribute]
            try {
              value = JSON.parse(value)
            } catch (e) {}
            if (value instanceof Array) {
              value = value.map(items => items.name).join(',')
            }
            label = label.replace(`{{${attribute}}}`, value || '')
          })
          result.push({
            id: item[el].id,
            entity: item[el],
            name: label,
            hideAddCard: hideAddCard,
            deleteBtnHidden: deleteBtnHidden,
            openBtnHidden: openBtnHidden,
            children: this.parseHtmlSearchTemplate(item, item[el].id),
            leaf: showLeaf
          })
        }
      }
      return result
    },
    parseHtmlTemplate (item) {
      let attributes = this.tree.htmlTemplate.match(/\{{(.*?)\}}/g) || []
      let label = this.tree.htmlTemplate
      attributes.forEach((attribute) => {
        attribute = attribute.replace('{{', '').replace('}}', '')
        let value = item[attribute]
        try {
          value = JSON.parse(value)
        } catch (e) {}
        if (value instanceof Array) {
          value = value.map(items => items.name).join(',')
        }
        label = label.replace(`{{${attribute}}}`, value || '')
      })
      let hideAddCard = !this.checkConditions(this.tree.addBtnHidden, true, item)
      let deleteBtnHidden = !this.checkConditions(this.tree.deleteBtnHidden, true, item)
      let openBtnHidden = !this.checkConditions(this.tree.openBtnHidden, true, item)
      let showLeaf = item.children_count === 0
      return {
        id: item.id,
        entity: item,
        name: label,
        hideAddCard: hideAddCard,
        deleteBtnHidden: deleteBtnHidden,
        openBtnHidden: openBtnHidden,
        children: [],
        leaf: showLeaf,
        popoverVisible: false
      }
    },
    initialDataForCard (parent) {
      let initialData = {}
      let defaults = this.getDefaultsForCard()
      defaults.forEach((item) => {
        initialData[item.key] = item.value
      })
      if (parent) initialData[this.defaultGroup] = this.selectedNode.id
      return initialData
    },
    getDefaultsForCard () {
      let defaults = []
      if (this.defaults) {
        this.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    }
  }
}
</script>

<style lang="scss" scoped>
.a-tree {
  .header_fixed {
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid rgb(228 231 237);
  }
  .background {
    background-color: #f5f5f5;
    position: sticky;
    top: 0px;
    z-index: 3;
  }
}
</style>
